<template>
  <v-container>
    <base-material-card icon="mdi-truck" title="Ruta" class="px-5 py-3">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-text-field label="Nombre de la ruta" v-model="form.nombre"
                            :error-messages="errors.nombre" class="required">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <custom-select url="/vendedores/all"
                             text="razon_social"
                             label="Vendedor"
                             @change="(value)=>{form.vendedor_id = value;}"
                             :valor="form.vendedor_id"
                             :error-messages="errors.vendedor_id"
              ></custom-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.dias"
                chips
                deletable-chips
                multiple
                label="Dias de Visitas"
                item-text="nombre"
                item-value="id"
                :items="dias"
                :error-messages="errors.dias"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" v-if="false">
              <custom-select
                url="/sucursales/sin_ruta"
                label="Sucursal"
                text="nombre_completo"
                placeholder="Seleccione una sucursal"
                :valor="form.sucursal"
                :paginate="true"
                :error-messages="error_sucursal"
                @change="(value) => (form.sucursal = value)"
              ></custom-select>
            </v-col>
            <v-col cols="5">
              <custom-select url="/clientes/all"
                             text="razon_social"
                             label="Cliente"
                             @change="changeCliente"
                             :valor="form.cliente"
                             paginate
                             :error-messages="errors.cliente_id"
              ></custom-select>
            </v-col>
            <v-col cols="6">
              <custom-select url="/sucursales/all"
                             text="nombre"
                             label="Sucursal"
                             paginate
                             :valor="form.sucursal"
                             :params="{cliente_id:form.cliente_id}"
                             @change="changeSucursal"
                             :error-messages="error_sucursal"
              ></custom-select>
            </v-col>
            <v-col cols="1">
              <v-btn fab icon x-small @click="addSucursal">
                <v-icon>fa fa-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <custom-error :errors="errors.sucursales?errors.sucursales:''"></custom-error>
              <v-data-table
                v-if="false"
                :headers="headers"
                :items="form.sucursales"
                item-key="name"
                class="elevation-1"
              >
                <template v-slot:[`item.numero`]="{item, index}">
                  {{index + 1}}
                </template>
                <template v-slot:[`item.acciones`]="{item, index}">
                  <v-btn fab icon x-small v-if="form.sucursales.length-1 != index"
                         @click="bajarSucursal(index)">
                    <v-icon small>fa fa-arrow-down</v-icon>
                  </v-btn>
                  <v-btn fab icon x-small v-if="index != 0"
                         @click="subirSucursal(index)">
                    <v-icon small>fa fa-arrow-up</v-icon>
                  </v-btn>
                  <v-btn fab icon x-small
                         @click="eliminarSucursal(item)">
                    <v-icon>fa fa-times</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-data-table
                :headers="headers"
                :items="form.sucursales"
                item-key="name"
                class="elevation-1"
                :show-select="false"
                :disable-pagination="true"
                :hide-default-footer="true"
              >
                <template v-slot:body="props">
                  <draggable
                    v-model="form.sucursales"
                    tag="tbody"
                  >
                    <tr
                      v-for="(sucursal, index) in props.items"
                      :key="index"
                    >
                      <td>
                        <v-icon
                          small
                          class="page__grab-icon"
                        >
                          mdi-arrow-all
                        </v-icon>
                      </td>
                      <td> {{ index + 1 }} </td>
                      <td> {{ sucursal.cliente.razon_social }} </td>
                      <td> {{ sucursal.nombre }} </td>
                      <td>
                        <v-btn fab icon x-small
                               @click="eliminarSucursal(sucursal)">
                          <v-icon>fa fa-times</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="saveRuta()"
                 :loading="loading">Guardar
          </v-btn>
          <v-btn @click="$router.push({name: `Rutas`})"
                 :loading="loading">Cancelar
          </v-btn>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>
import CustomSelect from '@views/dashboard/component/CustomSelect.vue'
import CustomError from '@views/dashboard/component/CustomError.vue'
import draggable from 'vuedraggable'

const MODEL = {
  nombre: '',
  vendedor_id: '',
  cliente_id: '',
  cliente: '',
  sucursal_id: '',
  dias: [],
  sucursal: '',
  sucursales: [],
}
export default {
  name: 'RutasForm',
  components: {
    CustomSelect,
    CustomError,
    draggable
  },
  data: () => ({
    nameSection: "Rutas",
    form: MODEL,
    errors: {},
    error_sucursal: "",
    loading: false,
    dias: [
      {
        id: 1, nombre: 'Domingo'
      },
      {
        id: 2, nombre: 'Lunes'
      },
      {
        id: 3, nombre: 'Martes'
      },
      {
        id: 4, nombre: 'Miercoles'
      },
      {
        id: 5, nombre: 'Jueves'
      },
      {
        id: 6, nombre: 'Viernes'
      },
      {
        id: 7, nombre: 'Sabado'
      }
    ],
    headers: [
      {text: "-", value: "drag", sortable: false},
      {text: "No.", value: "numero", sortable: false},
      {text: "Cliente", value: "cliente.razon_social", sortable: false},
      {text: "Sucursal", value: "nombre", sortable: false},
      {text: "Acciones", value: "acciones", sortable: false},
    ],
  }),
  mounted() {
    this.form = this.clonar(MODEL);
    if (this.$route.params.id) {
      this.loadData()
    }
  },
  methods: {
    changeSucursal(item){
      console.log('changeSucursal',item);
      //this.form.sucursal_id = sucursal
      if(item){
        console.log('seleccionando sucursal', item);
        this.form.sucursal = item;
        this.form.sucursal_id = item.id
      }else{
        this.form.sucursal = '';
        this.form.sucursal_id = ''
      }
    },
    changeCliente(cliente){
      this.form.cliente = cliente;
      if(cliente && cliente.id){
        this.form.cliente_id = cliente.id;
      }
    },
    selectSucursal(item){
      if(item){
        console.log('seleccionando sucursal', item);
        this.form.sucursal = item;
        this.form.sucursal_id = item.id
      }else{
        this.form.sucursal = '';
        this.form.sucursal_id = ''
      }

    },
    arrayMode(initialIndex, finalIndex) {
      this.form.sucursales.splice(finalIndex, 0, this.form.sucursales.splice(initialIndex, 1)[0])
    },
    subirSucursal(index) {
      this.arrayMode(index, index - 1)
    },
    bajarSucursal(index) {
      this.arrayMode(index, index + 1)
    },
    eliminarSucursal(sucursal) {
      this.form.sucursales = this.form.sucursales.filter((item) => item != sucursal);
    },
    addSucursal() {
      this.error_sucursal = "";
      if (this.form.sucursal) {
        console.log('sucursal',this.form.sucursal_id);
        console.log('cliente',this.form.cliente_id);
        console.log('sucursal',this.form.sucursal);
        const existSucursal = this.form.sucursales.filter(item=>{
          return item.id == this.form.sucursal.id;
        });
        console.log(existSucursal);
        if(existSucursal && existSucursal.length){
          this.error_sucursal = "Sucursal seleccionada ya existe en el listado";
          return;
        }
        this.form.sucursales.push(this.form.sucursal);
        this.form.sucursal=null;
        this.form.sucursal_id='';
        this.form.cliente_id='';
        this.form.cliente='';
        return;
      }
      this.error_sucursal = "Debe de seleccionar una sucursal";
    },
    loadData() {
      this.$http.get(`/rutas/${this.$route.params.id}`).then(response => {
        if (response.data.dias) {
          response.data.dias = response.data.dias.map(item => item.id);
        }
        response.data['cliente_id'] = '';
        response.data['cliente'] = '';
        this.form = response.data
      })
    },
    saveRuta() {
      this.errors = {};
      this.loading = true;
      if (this.$route.params.id) {
        this.updateData();
        return
      }
      this.createData()
    },
    createData() {
      this.errors = {};
      this.$http.post('/rutas', this.form).then(response => {
        this.form = this.clonar(MODEL);
        this.$router.push({name: this.nameSection})
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = this.errors = error.response.data.errors;
            break;
          default:
            break;
        }
      }).finally(() => {
        this.loading = false
      })
    },
    updateData() {
      this.$http.put(`/rutas/${this.$route.params.id}`, this.form)
        .then(() => {
          this.form = this.clonar(MODEL);
          this.$router.push({name: this.nameSection})
        }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          default:
            break;
        }
      }).finally(() => {
        this.loading = false
      })
    },
    toFormData(object, method) {
      let formData = new FormData;

      if (method) {
        formData.append('_method', method)
      }

      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const element = object[key];
        }
        if (object[key]) {
          if (Array.isArray(object[key])) {
            object[key].forEach(subobject => {
              formData.append(`${key}[]`, subobject)
            })
          } else {
            formData.append(key, object[key])
          }
        }
      }
      return formData
    }
  }
}
</script>
